(function ($) {
	var topicbox = {
		options: {headerSelector: '.topicbox-header', headerInnerSelector: '.topicbox-header-inner', headerInnerOpenClass: 'topicbox-header-inner-open', contentSelector: '.topicbox-content'}, clickHeader: function (e) {
			var $this = $(this);
			if (($this).hasClass('no-script')) return;
			$this.find(topicbox.options.headerInnerSelector).toggleClass(topicbox.options.headerInnerOpenClass);
			$this.next(topicbox.options.contentSelector).slideToggle();
		}, init: function () {
			$(topicbox.options.headerSelector).on('click', topicbox.clickHeader);
		}
	};
	$(document).ready(topicbox.init);
}(jQuery));